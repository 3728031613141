
import './style.scss';
import Centerheading from '../../Component/Centerheading/Centerheading';
import {Container, Image } from 'react-bootstrap';
import Twitter from '../../Static/twitter-color-black.svg';
import Instagram from '../../Static/InstagramLogo-black.svg';
import Discard from '../../Static/Discard-black.svg';
import Logomark from '../../Static/Logomark-black.svg';
import Logo from '../../Static/WednesdaysNFT.svg';
import Footerlogo1 from '../../Static/Footerlogo-1.png';
import Footerlogo2 from '../../Static/Footerlogo-2.png';
import Footerlogo3 from '../../Static/Footerlogo-3.png';
import Footerlogo4 from '../../Static/Footerlogo-4.png';
import Footerlogo5 from '../../Static/Footerlogo-5.png';

function Footer(){
    
    return(
        <>
        <div className='Footer_main'>
        <Container>
            <div className='Footer-logo ftstar'>
                <Image src={Footerlogo1} alt="" className='footerlogo_image1' />
                <Image src={Footerlogo2} alt="" className='footerlogo_image2'/>
                <Image src={Footerlogo3} alt="" className='footerlogo_image3'/>
                <Image src={Footerlogo4} alt="" className='footerlogo_image4'/>
                <Image src={Footerlogo5} alt="" className='footerlogo_image5'/>
            </div>

            <div className='SocialList'>
                <ul>
                    <li> <a href="#home"> <Image src={Twitter} alt="" /> </a></li>
                    <li> <a href="#home"> <Image src={Instagram} alt="" /> </a></li>
                    <li> <a href="#home"> <Image src={Discard} alt="" /> </a></li>
                    <li> <a href="#home"> <Image src={Logomark} alt="" /> </a></li>
                </ul>
            </div>

            <div className='footer_links'>
                <ul>
                    <li> <a href="https://www.wednesdaysnft.com/#story"> Story </a></li>
                    <li> <a href="https://www.wednesdaysnft.com/#ourteam"> Team </a></li>
                    <li> <a href="https://www.wednesdaysnft.com/#roadmap"> Roadmap </a></li>
                    <li> <a href="https://www.wednesdaysnft.com/#Utility"> Utility </a></li>
                    <li> <a href="https://www.wednesdaysnft.com/#partners"> Partners </a></li>
                </ul>
            </div>

            <div className='footer_Copyright'>
                <p>©2022 Wednesdays NFT. All Rights Reserved.</p>
            </div>

        </Container>
        </div>
        </>
    )
};
export default Footer;