import Home from '../../Home';
import Header from '../../Component/Header/Header';
import Accordian from '../../Component/Accordian/Accordian';
import Footer from '../../Component/Footer/Footer';
import Topbanner from './Topbanner';
import './style.scss';


function Landingpage(props:any){
    return(
        <>
            <div className='Darkbg_color background-topimage'>
                <Header />
                <Topbanner />
                
            </div>
            
            <div className='Minting_miansection'>
                <Home
                candyMachineId={props.candyMachineId}
                connection={props.connection}
                txTimeout={props.txTimeout}
                rpcHost={props.rpcHost}
                network={props.network}
                />

                <Accordian />
            </div>

            <Footer />

        </>
    )
};
export default Landingpage;