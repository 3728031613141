
import './style.scss';

function Centerheading(props:any){
    
    return(
        <>
           <div className={props.classcenterheading}>
               <h2>{props.centerheading}</h2>
               <p>{props.centerdescription}</p>
           </div>
        </>
    )
};
export default Centerheading;