
import './style.scss';
import Centerheading from '../../Component/Centerheading/Centerheading';
import {Container, Accordion  } from 'react-bootstrap';
import Slider from '../../Component/Slider/Slider';

function Accordian(){
    
    return(
        <>
        <Container>
           <div className='Accordian_space'>

           <Centerheading classcenterheading="centerheading_main centercoloryellow"
            centerheading="FAQ"
            />



            <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Hey $SCY Fam Welcome Back! We've been a bit quiet, we're going to do our best to make it up to you! So you are asking how? & what's the deal with The Wednesdays NFT?</Accordion.Header>
                        <Accordion.Body>
                        The Wednesdays NFT (TWNFT) is a collection of XXX algorithmically generated frog NFTs. While they are the dopest frawgs on the block(chain), The Wednesdays NFT is truly meant to be a utilitarian tool in the burgeoning Synchrony ecosystem. The Wednesdays NFT is a collaborative effort with original Synchrony community members.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>What is The Wednesdays NFT Utility?</Accordion.Header>
                        <Accordion.Body>
                        <p>
                        <b>The *initial* utility for The Wednesdays NFT's:</b>
                        <br />
                            1. $SCY Staking Boost! (2X)Increase your $SCY Single Sided Staking Emissions by 200% in the Synchrony Native Staking App.
                            <br />
                            2. Gated Access!NFT-holders are granted access to discord channels with curated content, research tools, &amp; discord-alpha-bots to further consolidate when, where, and how you DYOR.
                            <br />
                            3. TBA We can't give out all the alpha at once…We're going to keep some surprises for the coming weeks!
                            <br />
                            We'll be working with our community of The Wednesday's NFT holders to help steer the course of The Wednesdays NFT lore, roadmap, next generations, development, utility, &amp; Synchrony DAO.
                        </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header> How to Get TWNFT Airdrop?</Accordion.Header>
                        <Accordion.Body>
                        <p>
                            All Synchrony IDO participants &amp; $SCY holders are eligible for a The Wednesdays NFT airdrop if…

                            You are still holding your IDO allocation from our [Solanium]<a href="https://www.solanium.io/" className="link">(https://www.solanium.io/)</a> &amp; [SolRazr]<a href="https://www.solanium.io/" className="link">(https://solrazr.com/)</a> IDOsOR If you hold XXX amount of $SCY after XX/XX/2022

                            We'll be taking the snapshot of $SCY holders at random over the course of the next few weeks. (Holding $SCY will essentially act as your WL for the airdrop but how much do you need to hold?!)
                        </p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>How to Mint TWNFT? </Accordion.Header>
                        <Accordion.Body>
                        Once all eligible wallet addresses have had their TWNFT's airdropped, we'll open up the public mint to anyone who wants to join us, all you have to do is pay 0.069 SOL + gas. Yea, that's right, 0.069**◎!
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div>
        </Container>
        
        </>
    )
};
export default Accordian;