
import './style.scss';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Logo from '../../Static/WednesdaysNFT.svg';
import SliderImage1 from '../../Static/SliderImage1.png';
import SliderImage2 from '../../Static/SliderImage2.png';
import SliderImage3 from '../../Static/SliderImage3.png';
import { Image } from 'react-bootstrap';

function Slider(){

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      const CustomButtonGroupAsArrows:any = ({ next , previous, ...rest }) => {
        const { carouselState: { currentSlide } } = rest;
        return (
          <div className="carousel-button-group">             
            <button aria-label="Go to previous slide" onClick={() => previous()} className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" type="button"></button>
            <button aria-label="Go to next slide" onClick={() => next()} className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right" type="button"></button>
          </div>
        );
      };
    
    return(
        <>
            <div className='Topbannerslider_space'>
                <Carousel responsive={responsive}
                itemClass="carousel-item-padding-40-px"
                autoPlay={false} 
                infinite={true}
                autoPlaySpeed={15000} 
                renderButtonGroupOutside={true}
                customButtonGroup={<CustomButtonGroupAsArrows />}
                  >
                        <div className='slider_images'> <Image src={SliderImage1} alt="" /></div>
                        <div className='slider_images'> <Image src={SliderImage2} alt="" /></div>
                        <div className='slider_images'> <Image src={SliderImage3} alt="" /></div>
                        <div className='slider_images'> <Image src={SliderImage2} alt="" /></div>
                        <div className='slider_images'> <Image src={SliderImage1} alt="" /></div>
                        <div className='slider_images'> <Image src={SliderImage3} alt="" /></div>
                    </Carousel>
                    
            </div>
        </>
    )
};
export default Slider;