import Navbar from 'react-bootstrap/Navbar';
import {Container, Nav, NavDropdown, Image } from 'react-bootstrap';
import Logo from '../../Static/WednesdaysNFT.svg';
import Twitter from '../../Static/twitter-color-black.svg';
import Instagram from '../../Static/InstagramLogo-black.svg';
import Discard from '../../Static/Discard-black.svg';
import Logomark from '../../Static/Logomark-black.svg';
import './style.scss';

function Header(){

    const handleToggle = () => {
        document.body.classList.toggle("fixmenu");
     }
    
    return(
        <>
           <Navbar collapseOnSelect className='header-navbar' expand="lg">
                <Container>
                    <Navbar.Brand href="https://www.wednesdaysnft.com/"> 
                        <Image src={Logo} alt="" /> 
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
                    <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto-header">
                        <Nav.Link href="https://www.wednesdaysnft.com/">Home</Nav.Link>
                        <Nav.Link href="https://www.wednesdaysnft.com/#story">Story</Nav.Link>
                        <Nav.Link href="https://www.wednesdaysnft.com/#ourteam">Team</Nav.Link>
                        <Nav.Link href="https://www.wednesdaysnft.com/#roadmap">Roadmap</Nav.Link>
                        <Nav.Link href="https://www.wednesdaysnft.com/#partners">Partners</Nav.Link>
                    </Nav>

                    <div className='SocialList'>
                        <ul>
                            <li> <a href="#home"> <Image src={Twitter} alt="" /> </a></li>
                            <li> <a href="#home"> <Image src={Instagram} alt="" /> </a></li>
                            <li> <a href="#home"> <Image src={Discard} alt="" /> </a></li>
                            <li> <a href="#home"> <Image src={Logomark} alt="" /> </a></li>
                        </ul>
                    </div>

                    </Navbar.Collapse>

                    
                </Container>
            </Navbar>
        </>
    )
};
export default Header;