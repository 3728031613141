
import './style.scss';
import Centerheading from '../../Component/Centerheading/Centerheading';
import {Container } from 'react-bootstrap';
import Slider from '../../Component/Slider/Slider';

function Topbanner(){
    
    return(
        <>
        <Container>
           <div className='Topbanner_space'>
           <Centerheading classcenterheading="centerheading_main centercolorwhite"
            centerheading="Wednesaday NFTs Buy and Sell"
           centerdescription="Wednesday’s NFT began as a collective of like-minded artists and builders who share a love of blockchain technology and frogs. The Wednesdays creative team spent months conceptualizing their favorite frogs, taking inspiration from movies, anime, gaming, and absurdity. The development team focused on implementing ways to add utility to their passion for frogs. While all of Wednesdays’ NFTs are limited, the community is welcoming to users of all experience levels; with a primary goal to inspire new users to join the blockchain revolution." />

            <Slider/>

            </div>

        </Container>
        
        </>
    )
};
export default Topbanner;